import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

import newPhoneImage from '../images/new-phone.jpg';
import usedPhoneImage from '../images/used-phone.jpg';

const IpTv = () => (
  <Layout>
    <div>
      <section className="breadcrumb-area">
        <div className="container text-center">
          <h1>New/Certified Used Phone</h1>
        </div>
      </section>
      {/* End breadcrumb area */}
      {/* Start breadcrumb bottom area */}
      <section className="breadcrumb-botton-area">
        <div className="container">
          <div className="left pull-left">
            <ul>
              <li><a href="index.html">Home</a></li>
              <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
              <li><Link to="/buy-mobile-phone">Mobile Phone</Link></li>
            </ul>
          </div>
          <div className="right pull-right">
            {/* <a href="#">
              <i className="fa fa-share-alt" aria-hidden="true" />
Share
            </a> */}
          </div>
        </div>
      </section>
      {/* End breadcrumb bottom area */}
      {/* Start service single area */}
      <section id="service-single-area" className="smartphone-repair-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xs-12 pull-right">
              <div className="service-single-content">
                <div className="row top-content">
                  <div className="col-md-5 col-sm-12 col-xs-12">
                    <div className="img-holder">
                      <img src={newPhoneImage} alt="Awesome" />
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12 col-xs-12">
                    <div className="text-holder">
                      <div className="title">
                        <h2>New Phone</h2>
                      </div>
                      <div className="text">
                        <strong>
                          <p>New Phones start from 79.99 $</p>
                          <p>Check out new flagship devices (Apple/Samsung) price before get into contract.</p>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xs-12 pull-right">
              <div className="service-single-content">
                <div className="row middle-content">
                  <div className="col-md-7 col-sm-12 col-xs-12">
                    <div className="text-holder">
                      <div className="title">
                        <h2>Certified Used Phone</h2>
                      </div>
                      <div className="text">
                        <h3>Cell Tech For Less Certified Pre-owned</h3>
                        <strong>
                          <ol>
                            <li>The Smarter way to buy smart devices.</li>
                            <li>Certified means your device is guaranteed</li>
                            <li>Amazing deals on firm used devices.</li>
                            <li>Wide rage of selection</li>
                            <li>No Obligation Buy Outwrite</li>
                            <li>Fully inspected</li>
                            <li>45 Days warranty on all devices.</li>
                          </ol>
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-12 col-xs-12">
                    <div className="img-holder">
                      <img src={usedPhoneImage} alt="Awesome" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  </Layout>
);
export default IpTv;
